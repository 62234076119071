<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-card-title>
            Reporte por Rubros
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :sort-by="['junior']"
            :headers="headers"
            :items="itemsRSenior"
            :search="search"
          >
            <template slot="body.append">
              <tr class="pink--text">
                <th colspan="4" class="title">Totales</th>
                <th class="title">{{ sumField("cantidad") }}</th>
                <th class="title">{{ sumField("subtotal") }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogrs" width="1000">
      <v-card>
        <v-card-title>
          Lista de {{ senior }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchSS"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headersSS"
          :items="itemsRSeniorSS"
          :search="searchSS"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2 mx-auto" @click="viewOrden(item.id)">
              mdi-eye
            </v-icon>
          </template>

          <template slot="body.append">
            <tr class="pink--text">
              <th class="title">Totales</th>
              <th class="title">{{ sumFieldS("cantidad") }}</th>
              <th class="title">{{ sumFieldS("precio") }}</th>
              <th class="title">{{ sumFieldS("subtotal") }}</th>
            </tr>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogrs = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "RSenior",
  data: () => ({
    search: "",
    searchS: "",
    searchSS: "",
    senior: "",
    itemsRSenior: [],
    itemsRSeniorS: [],
    itemsRSeniorSS: [],
    id: "",
    name: "",
    dialogrs: "",
    description: "",
    status: true,
    statusData: 1,

    headers: [
      {
        text: "Código",
        align: "start",

        value: "codigo_producto",
      },
      { text: "Proveedor", value: "proveedor" },
      { text: "Catálogo", value: "catalogo" },
      { text: "Rubro", value: "rubro" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Sub Total", value: "subtotal" },
    ],

    headersS: [
      {
        text: "Junior",
        align: "start",
        value: "junior",
      },
      { text: "SubTotal", value: "subtotal" },
      { text: "Acción", value: "actions" },
    ],

    headersSS: [
      {
        text: "Junior",
        align: "start",
        value: "junior",
      },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Sub Total", value: "subtotal" },
    ],
  }),
  mounted() {
    this.loadRSenior();
    this.loadRSeniorS();
  },
  methods: {
    loadRSenior() {
      axios.get(process.env.VUE_APP_API_URL + "rrubros").then((result) => {
        this.itemsRSenior = result.data;
      });
    },
    loadRSeniorS() {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/junior_with")
        .then((result) => {
          this.itemsRSeniorS = result.data;
        });
    },
    loadRSeniorSS(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/junior/" + id)
        .then((result) => {
          this.dialogrs = true;
          this.senior = result.data[0].senior;
          this.itemsRSeniorSS = result.data;
        });
    },
    editItem(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "RSenior/" + id)
        .then((result) => {
          this.id = result.data[0].id;
          this.name = result.data[0].name;
          this.description = result.data[0].description;
          this.status = result.data[0].status;
          this.statusData = 2;
        });
    },
    addData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "RSenior", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    updateData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "RSenior/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    sumField(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSenior.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorSS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },
    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, el rubro no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "RSenior/" + id)
            .then((result) => {
              console.log(result.data);
              if (result.data.boolean == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Rubro eliminado con éxito",
                  showConfirmButton: true,
                  timer: 5000,
                });
                this.loadRSenior();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Oops...",
                  text: result.data.error.sqlMessage,
                  showConfirmButton: true,
                  timer: 8000,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "El rubro no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.name = "";
      this.description = "";
      this.status = true;
      this.statusData = 1;
    },
  },
};
</script>
